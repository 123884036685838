<template>
  <div>
    <!-- 内容 -->
    <div class="displya_flex">
      <!-- 左侧 -->
      <div style="width: 30%">
        <div class="displya_flex">
          <!-- 头部 -->

          <div style="width: 90%; margin-bottom: 10px">
            <el-radio-group v-model="radio2" @change="Radios">
              <el-radio-button label="药品" />
              <el-radio-button label="治疗项" />
            </el-radio-group>
          </div>
          <el-input
            style="width: 300px"
            v-model="searchs"
            clearable
            placeholder="请输入搜索信息"
          >
            <template #suffix>
              <div @click="search" class="searchcss">
                <i class="fa fa-search"></i>
              </div>
            </template>
          </el-input>
        </div>

        <!-- 表格 -->
        <div class="tables">
          <el-table
            v-loading="loading"
            :data="tableData"
            @row-click="rowclick"
            stripe
            border
            max-height="500px"
            style="min-width: 100%"
          >
            <el-table-column prop="name" label="名称" />
            <el-table-column prop="medi_spec" label="规格" />
            <el-table-column prop="dict_factory_name" label="厂商" />
            <el-table-column fixed="right" label="操作">
              <template #default="scope">
                <div class="arrow-right" @click="bound(scope.row.id)">绑</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination-box">
          <div class="displya_flex">
            <div></div>
            <div>
              <el-pagination
                @current-change="currentings"
                small
                background
                layout="prev, pager, next"
                :total="total"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- 右侧 -->

      <div class="rigthc_table">
        <!-- 顶部 -->
        <div class="rigth_top">
          <el-alert
            title="左侧选中组套所包含项目"
            type="warning"
            show-icon
            :closable="false"
          />
        </div>
        <!-- 表格 -->
        <div class="tables">
          <el-table
            :data="rightable"
            stripe
            max-height="500px"
            style="min-width: 100%"
            border
          >
            <el-table-column prop="set_name" label="组套名称" />
            <el-table-column prop="is_default" label="默认">
              <template #default="scope">
                <el-switch
                  @change="isdefault(scope.row.id)"
                  v-model="scope.row.switchs"
                  inline-prompt
                  active-text="是"
                  inactive-text="否"
                />
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="100">
              <template #default="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="delParient(scope.row.id)"
                >
                  <!-- <i class="txIons del"></i> -->
                  <span class="txfonts del">删</span>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination-box">
          <el-pagination
            v-model:currentPage="pageData.currentPage"
            v-model:page-size="pageData.pageSize"
            :page-sizes="[10, 20, 50, 100]"
            :background="true"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageData.totalSum"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </div>

    <!-- 新增弹窗 -->
    <div>
      <BaseDialog
        title="组套列表"
        :isshow="show"
        @handleShow="
          (val) => {
            show = val
          }
        "
        width="30%"
        height="auto"
      >
        <!-- 顶部输入框 -->
        <div class="adclisin">
          <el-input v-model="addsearchs" clearable placeholder="请输入搜索信息">
            <template #suffix>
              <div @click="addsearch" class="searchcss">
                <i class="fa fa-search"></i>
              </div>
            </template>
          </el-input>
        </div>
        <!-- 表格 -->
        <el-table
          ref="multipleTableRef"
          :data="leftableData"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column property="set_name" label="组套名称" />
        </el-table>
        <!-- 分页 -->
        <div class="pagination-box">
          <div class="displya_flex">
            <div></div>
            <div>
              <el-pagination
                @current-change="currenting"
                small
                background
                layout="prev, pager, next"
                :total="totals"
              />
            </div>
          </div>
        </div>
        <!-- 下划线 -->
        <el-divider />
        <!-- 底部按钮 -->
        <div class="displya_flex">
          <div></div>
          <el-row class="mb-4">
            <el-button @click="show = false">取消</el-button>
            <el-button type="primary" @click="comfing">确定</el-button>
          </el-row>
        </div>
      </BaseDialog>
    </div>
  </div>
</template>

<script>
import BaseDialog from '@/components/Dialog/index.vue'
import service from '@/utils/request'
import { toRefs, reactive, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
export default {
  components: {
    BaseDialog,
  },
  setup() {
    const state = reactive({
      pageData: {
        currentPage: 1,
        pageSize: 10,
        totalSum: 0,
      },
      current: 1,
      total: 0,
      totals: 0,
      show: false,
      sid: null,
      loading: true,
      searchs: '',
      addsearchs: '',
      radio2: '药品',
      tableData: [],
      rightable: [],
      leftableData: [],
      set_id: [],
    })
    onMounted(() => {
      project()
    })
    // 绑定组套列表
    const bangding = async (id) => {
      let data = {
        drug_id: id,
        current: state.current,
        size: 20,
      }
      let res = await service.post('/api/setup/getZutao', data)
      if (res.code === 0) {
        if (res.data.total > 0) {
          state.leftableData = res.data.list
          state.totals = res.data.total
        }
      }
    }
    // 右侧设置默认
    const isdefault = async (id) => {
      let data = {
        pro_id: id,
        drug_id: state.sid,
      }
      let res = await service.post('/api/setup/setDefault', data)
      if (res.code === 0) {
        ElMessage({
          message: '设置成功',
          type: 'success',
        })
        rightbody(state.sid)
      }
    }
    // 左侧点击请求数据
    const rowclick = async (row) => {
      state.sid = row.id
      rightbody(row.id)
    }
    // / 右侧表格列表
    const rightbody = async (id) => {
      let data = {
        drug_id: id,
        current: state.pageData.currentPage,
        size: state.pageData.pageSize,
      }
      let res = await service.post('/api/SetUp/getDrugSet', data)
      if (res.code === 0) {
        console.log(res)
        if (res.data.total > 0) {
          state.rightable = res.data.records
          state.pageData.totalSum = res.data.total
          state.rightable.map((v) => {
            if (v.is_default == 0) {
              v.switchs = false
            } else {
              v.switchs = true
            }
          })
        } else {
          state.rightable = []
          state.pageData.totalSum = 0
        }
      }
    }
    // 右侧表格数据删除
    const delParient = async (id) => {
      let res = await service.post('/api/setup/deleteProject', { id: id })
      if (res.code === 0) {
        ElMessage({
          message: '删除成功',
          type: 'success',
        })
        rightbody(state.sid)
      }
    }
    // 新增全选
    const handleSelectionChange = (e) => {
      state.set_id = []
      e.map((v) => {
        state.set_id.push(v.id)
      })
    }
    // 新增保存
    const comfing = async () => {
      let data = {
        drug_id: state.sid,
        set_id: state.set_id,
      }
      let res = await service.post('/api/setup/saveBind', data)
      if (res.code === 0) {
        ElMessage({
          message: '添加成功',
          type: 'success',
        })
        state.show = false
        rightbody(state.sid)
      }
    }
    // 搜索
    const search = () => {
      project()
    }
    // 绑定搜索
    const addsearch = () => {
      bangding()
    }
    // 绑定组套
    const bound = (id) => {
      state.sid = id
      state.show = true
      bangding(id)
    }
    // 单选
    const Radios = () => {
      if (state.radio2 == '药品') {
        state.dict_catalog_type = '160000000'
      } else if (state.radio2 == '治疗项') {
        state.dict_catalog_type = '161000000'
      }
      project()
    }
    // 绑定组套左侧列表页数改变
    const currenting = (e) => {
      state.current = e
      bound(state.sid)
    }

    // 获取左侧药品列表
    const project = async () => {
      state.loading = true
      let data = {
        current: state.addcurrent,
        size: 20,
        type: '7',
        dict_type: state.dict_catalog_type,
        pinyin: state.searchs,
        dict_catalog_type: state.dict_catalog_type,
      }
      let res = await service.post('/api/setup/setBind', data)
      if (res.code === 0) {
        if (res.data.drugtotal > 0) {
          state.tableData = res.data.list
          // 右侧数据列表
          state.rightable = res.data.projectList
          state.sid = res.data.list[0].id
          state.rightable.map((v) => {
            if (v.is_default == 0) {
              v.switchs = false
            } else {
              v.switchs = true
            }
          })
          state.pageData.totalSum = res.data.projecttotal
          state.total = res.data.drugtotal
        } else {
          state.tableData = []
        }
      }
      state.loading = false
    }
    // 左侧页数改变时
    const currentings = (e) => {
      state.currentings = e
      project()
    }
    return {
      ...toRefs(state),
      bound,
      project,
      Radios,
      currentings,
      currenting,
      bangding,
      search,
      handleSelectionChange,
      addsearch,
      comfing,
      rowclick,
      isdefault,
      rightbody,
      delParient,
    }
  },
}
</script>

<style scoped lang="scss">
.displya_flex {
  display: flex;
  justify-content: space-between;
  .searchcss {
    cursor: pointer;
  }
}
.rigthc_table {
  margin-left: 20px;
  width: calc(100% - 30%);
  &:deep(.el-alert--warning.is-light) {
    background-color: rgba(49, 102, 174, 0.1);
    color: #3166ae;
  }
  .rigth_top {
    margin-bottom: 10px;
  }
}
.arrow-right {
  width: 21px;
  height: 21px;
  background: rgba(64, 158, 255, 1);
  opacity: 1;
  color: #fff;
  border-radius: 7px;
  text-align: center;
  cursor: pointer;
}
.adclisin {
  margin: 10px 0;
  width: 300px;
}
.del {
  background-color: #ffecec;
  color: #ff6b6b;
  font-weight: 700;
}
</style>
